<template>
  <div class="content-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ContentContainer",
};
</script>

<style lang="scss" scoped>
.content-container {
  margin: 20px 115px 0 20px;
  padding: 30px 20px;
  background: $g-color-white;
  border-radius: 10px;
  border: 1px solid $mainDisabled;
}
@media (max-width: 1500px) {
  .content-container {
    margin-right: 15px;
  }
}
</style>
