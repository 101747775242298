<template>
  <div class="navigation-menu">
    <div class="navigation-menu__container">
      <div class="navigation-menu__header"></div>
      <div class="navigation-menu__links">
        <NavigationMenuLinks />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationMenuLinks from "../molecules/NavigationMenuLinks.vue";
export default {
  components: {
    NavigationMenuLinks,
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu {
  min-width: 210px;
  max-width: 220px;
  border-right: 1px solid $mainDisabled;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);

  &__container {
    position: relative;
    height: 100%;
  }

  &__links {
    position: sticky;
    top: 0;
  }

  &__header {
    height: 60px;
    border-bottom: 1px solid $mainDisabled;
  }
}
</style>
