<template>
  <div class="header">
    <div></div>
    <!-- <HeaderSiteController /> -->
    <HeaderUser />
    <MainModal
      ref="modalAccesses"
      class="modal-accesses"
      :on-close="handleCloseAccessesModal"
      :modal-center="true"
    >
      <div class="list__confirm">
        <div class="list__confirm-title">You don't have accesses!</div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseAccessesModal">OK</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleGoToLogin" :button-cancel="true">RELOGIN</MainButton>
          </div>
        </div>
      </div>
    </MainModal>
  </div>
</template>

<script>
// import HeaderSiteController from "./HeaderSiteController.vue";
import HeaderUser from "./HeaderUser.vue";
import MainModal from "../../components/molecules/MainModal.vue";
import MainButton from "../../components/atoms/buttons/MainButton.vue";
import { mapState } from "vuex";

export default {
  name: "Header",
  components: {
    // HeaderSiteController,
    HeaderUser,
    MainModal,
    MainButton,
  },
  computed: {
    ...mapState("users", ["errorAuth"]),
  },

  methods: {
    handleCloseAccessesModal() {
      this.$refs.modalAccesses.$el.classList.remove("active");
    },
    handleGoToLogin() {
      this.$store.dispatch("users/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background: $coffee;

  &__site-choose {
    color: $g-color-white;
    font-weight: bold;
  }

  &__site-list {
    list-style: none;
  }

  .modal-accesses {
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>
