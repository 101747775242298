<template>
  <nav class="navigation-menu-links">
    <ul class="navigation-menu-links__items">
      <li class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('HOME_PAGE', false)" tag="a" exact> Главная </router-link>
        </div>
      </li>

      <li class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('DEVELOPER_LIST', true)" tag="a" exact>
            Застройщик
          </router-link>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import ROUTES from "~/constants/routes";
import { getStoredUserInfo } from "~/helpers/userAuth";
export default {
  name: "NavigationMenuLinks",
  data() {
    return {
      openItem: "",
      user: {},
      admin: false,
    };
  },
  mounted() {
    // if (getStoredUserInfo()) {
    //   console.log(getStoredUserInfo());
    //   this.user = getStoredUserInfo();
    //   if (this.user.group[0].id == 2) {
    //     this.admin = true;
    //   } else {
    //     this.admin = false;
    //   }
    //   console.log(this.admin);
    // }
  },
  components: {},
  methods: {
    handleOpenList(item) {
      if (this.openItem === item) {
        this.openItem = "";
      } else {
        this.openItem = item;
      }
    },
    getRoutes(name, page = false) {
      if (page) {
        return ROUTES[name].replace(":page", 1);
      } else {
        return ROUTES[name];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu-links {
  &__item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid $mainDisabled;
    cursor: pointer;

    a {
      font-size: 14px;
      font-weight: bold;
      color: $mainColor;
    }

    &.active {
      background: $coffee;
      border-color: transparent;

      a {
        color: $g-color-white;
      }

      .navigation-menu-links__button {
        display: none;
      }
    }
  }

  &__item-second {
    padding: 12px;
    border-bottom: 1px solid $mainDisabled;

    a {
      color: $mainSecondTextColor;
      font-size: 12px;

      &:hover {
        color: $mainColor;
      }
    }
  }
}
</style>
