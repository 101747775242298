<template>
  <div class="tools-panel">
    <div class="tools-panel__left">
      <div v-if="breadCrumbFirst.length > 0" class="tools-panel__breadcrumbs">
        <router-link
          v-if="breadCrumbFirstLink.length > 0"
          :to="breadCrumbFirstLink"
          class="tools-panel__breadcrumb-first"
          >{{ breadCrumbFirst }}</router-link
        >
        <div v-else class="tools-panel__breadcrumb-first">{{ breadCrumbFirst }}</div>
        <div v-if="breadCrumbSecond.length > 0" class="tools-panel__breadcrumb-divider"></div>
        <div v-if="breadCrumbSecond.length > 0" class="tools-panel__breadcrumb-second">
          {{ breadCrumbSecond }}
        </div>
      </div>
      <h3 v-if="title.length > 0" class="tools-panel__title">{{ title }}</h3>
    </div>
    <div class="tools-panel__right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolsPanel",
  props: {
    breadCrumbFirst: {
      type: String,
      default: "",
    },
    breadCrumbFirstLink: {
      type: String,
      default: "",
    },
    breadCrumbSecond: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.tools-panel {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 115px 0 20px;
  width: 100%;
  height: 60px;
  background: $g-color-white;
  border-bottom: 1px solid $mainDisabled;
  z-index: 100000;

  &__breadcrumbs {
    display: flex;
    padding-bottom: 2px;
  }

  &__title {
    font-size: 20px;
    color: $mainSecondTextColor;
  }

  &__breadcrumb-first {
    color: $coffee;
    font-size: 12px;
    font-weight: bold;
  }

  &__breadcrumb-second {
    color: $mainColor;
    font-size: 12px;
    font-weight: bold;
  }

  &__breadcrumb-divider {
    position: relative;
    top: -1px;
    margin: auto 7px;
    width: 5px;
    height: 5px;
    background: $mainColor;
    border-radius: 50px;
  }
}

@media (max-width: 1500px) {
  .tools-panel {
    padding-right: 15px;
  }
}
</style>
